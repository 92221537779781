<template>
  <ion-slides v-if="ads" :options="slideOpts" ref="slides" @ionSlidesDidLoad="onSlidesLoaded">
    <ion-slide v-for="ad in ads" :key="ad.uuid">
      <a
        href="#"
        draggable="false"
        rel="nofollow sponsored"
        @click.prevent="openAdUrl(ad)"
      >
        <img
          v-observe-visibility="{
            callback: (isVisible) => onAdVisible(isVisible, ad),
            throttle: ad_throttle,
            intersection: {
              threshold: 0.8
            }
          }"
          draggable="false"
          :src="adImage(ad)"
          :alt="ad.title"
        >
      </a>
    </ion-slide>
  </ion-slides>
</template>

<style scoped>
  ion-slide a {
    display: flex;
    width: 100%;
  }

  ion-slide img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 16/3;
  }

  ion-slides {
    --bullet-background: var(--ion-color-tertiary);
    --bullet-background-active: var(--ion-color-moto)
  }
</style>

<script>
  import { GET_ADS } from '@/graphql/queries';
  import { RECORD_AD_EVENT_ACTION } from "../store/store-actions";
  import { AdEventType } from "../graphql/enums";
  import { shuffle } from 'lodash'
  import { APP_DEFAULTS } from "../configs";
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "../store/store-getters";
  import { Browser } from "@capacitor/browser";
  import { captureException } from "../utils";
  import { Capacitor } from "@capacitor/core";

  export default {
    props: {
      adSlot: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        browserOpened: false,
        apiUrl: process.env.VUE_APP_API_URL,
        storageUrl: process.env.VUE_APP_API_STORAGE_URL,
        slideOpts: {
          initialSlide: 0,
          speed: APP_DEFAULTS.AD_SPEED,
          autoplay: {
            delay: APP_DEFAULTS.AD_SPEED,
            disableOnInteraction: false,  // Autoplay continues even after manual slide change
          }
        },
        ad_throttle: APP_DEFAULTS.AD_THROTTLE,
      }
    },
    computed: {
      ...mapGetters([CURRENT_USER_GETTER]),
    },
    apollo: {
      ads: {
        query: GET_ADS,
        variables() {
          return {
            slots: [this.adSlot],
          }
        },
        update: data => {
          // Randomize the ads shown
          return shuffle(data.getAds)
        }
      },
    },
    async beforeDestroy() {
      if (this.browserListener) {
        try {
          await this.browserListener.remove()
        } catch (e) {
          captureException(e)
        }
      }

      if (this.browserOpened && Capacitor.isNativePlatform()) {
        try {
          await Browser.close();
        } catch (e) {
          captureException(e);
        }
      }
    },
    methods: {
      openAdUrl(ad) {
        if (this.isInAppAd(ad)) {
          return this.openInternalAd(ad)
        }
        return this.openExternalAd(ad)
      },
      isInAppAd(ad) {
        return ad.redirect_url.startsWith(this.$appUrl)
      },
      async openInternalAd(ad) {
        await this.$store.dispatch(RECORD_AD_EVENT_ACTION, {ad_uuid: ad.uuid, event_type: AdEventType.CLICK})
        const url = new URL(ad.redirect_url)
        return this.$router.push(`${url.pathname}${url.search}`);
      },
      async openExternalAd(ad) {
        this.browserOpened = true;

        const url = this.adUrl(ad)

        this.browserListener = await Browser.addListener('browserFinished', () => {
          this.browserOpened = false;
        })

        try {
          await Browser.open({url, presentationStyle: 'popover'})
        } catch (e) {
          // Make sure we do not get any funny business
          captureException(e)
        }
      },
      async onSlidesLoaded(event) {
        const swiper = await this.$refs.slides.getSwiper();

        /**
         * Due to an issue with ion-slides, where swiper options are not parsed, we set them ourselves.
         * https://github.com/ionic-team/ionic-framework/issues/18795
         */
        swiper.params.autoplay.delay = this.slideOpts.autoplay.delay;
        swiper.params.autoplay.disableOnInteraction = this.slideOpts.autoplay.disableOnInteraction;

        // ... and start auto-play on the element
        event.target && event.target.startAutoplay();
      },
      onAdVisible(isVisible, ad) {
        if (!isVisible) {
          return;
        }

        this.$store.dispatch(RECORD_AD_EVENT_ACTION, {
          ad_uuid: ad.uuid,
          event_type: AdEventType.IMPRESSION
        });
      },
      adUrl(ad) {
        // Encode the user's ref into the url
        const userRef = this[CURRENT_USER_GETTER]?.id || '';
        return `${this.apiUrl}/redirect-to-ad/${ad.uuid}?ref=${userRef}`
      },
      adImage(ad) {
        return `${this.storageUrl}/${ad.image_url}`
      }
    }
  }
</script>