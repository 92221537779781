var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ads)?_c('ion-slides',{ref:"slides",attrs:{"options":_vm.slideOpts},on:{"ionSlidesDidLoad":_vm.onSlidesLoaded}},_vm._l((_vm.ads),function(ad){return _c('ion-slide',{key:ad.uuid},[_c('a',{attrs:{"href":"#","draggable":"false","rel":"nofollow sponsored"},on:{"click":function($event){$event.preventDefault();return _vm.openAdUrl(ad)}}},[_c('img',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: (isVisible) => _vm.onAdVisible(isVisible, ad),
          throttle: _vm.ad_throttle,
          intersection: {
            threshold: 0.8
          }
        }),expression:"{\n          callback: (isVisible) => onAdVisible(isVisible, ad),\n          throttle: ad_throttle,\n          intersection: {\n            threshold: 0.8\n          }\n        }"}],attrs:{"draggable":"false","src":_vm.adImage(ad),"alt":ad.title}})])])}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }