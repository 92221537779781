<template>
  <ion-page class="ion-page">

    <ion-fab slot="fixed" vertical="bottom" horizontal="end" edge="false" v-if="canCreateEvent">
      <ion-fab-button color="moto" @click="openCreateEventModal">
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ContentContainer>
      <div class="max-w-xl mx-auto">
        <Refresher @refresh="doRefresh($event)" />

        <List header-color="primary">
          <AdvertisementSlides ad-slot="top_events" margin-bottom />

          <ion-segment class="top" color="moto" scrollable :value="selectedCategory.key" @ionChange="selectCategory">
            <ion-segment-button v-for="categoryKey in Object.keys(categories)" :key="categoryKey" :value="categoryKey">
              {{ categories[categoryKey].label | uppercase }}
            </ion-segment-button>
          </ion-segment>

          <List
            :not-found-message="$t('noeventsfound')"
            :loading="$apollo.loading && !events"
            :loading-more="loadingMore"
            @loadMore="loadMore($event, 'events')"
          >
            <template v-if="events && events.data && events.data.length">
              <router-link
                v-for="event in events.data"
                :key="event.id"
                :to="{ name: 'event', params: { id: event.id }}">
                <EventItem :event="event" :show-facility="true" />
              </router-link>
            </template>
          </List>
        </List>
      </div>
    </ContentContainer>
  </ion-page>
</template>

<style scoped>

  ion-segment.top ion-segment-button {
    max-width: 100%;
  }

  ion-segment.top ion-segment-button.segment-button-checked {
    color: var(--ion-color-white) !important;
    background: var(--ion-color-primary) !important;
    border-bottom: 2px solid var(--ion-color-moto);
  }

  ion-segment.top ion-segment-button {
    color: var(--ion-color-white) !important;
    background: var(--ion-color-primary) !important;
  }

</style>
<script>
  import MotoPopOver from '@/mixins/PopOver'
  import { GET_EVENTS } from '@/graphql/queries'
  import { RadiusMixin, infiniteScrollMixin } from '@/mixins';
  import { EVENT_SLIDER_RADIUS_KEY, defaultPaginationConfig } from '@/configs';
  import EventItem from '@/components/items/EventItem';
  import * as moment from "moment";
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import SelectEventTypeModal from "@/components/modals/SelectEventTypeModal";
  import AdvertisementSlides from "@/components/AdvertisementSlides";

  export default {
    components: {
      EventItem,
      AdvertisementSlides,
    },
    mixins: [
      MotoPopOver,
      RadiusMixin,
      infiniteScrollMixin,
      // setUserPositionMixin,
    ],
    data() {
      const categories = {
        TRAINING: {
          label: this.$i18n.t("events"),
          key: "TRAINING"
        },
        COMPETITION: {
          label: this.$i18n.t("competitions"),
          key: "COMPETITION"
        }
      };

      return {
        categoryPopoverModal: null,
        radiusKey: EVENT_SLIDER_RADIUS_KEY,
        radiusSliderClosed: true,
        categories,
        selectedCategory: categories.TRAINING,
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
      canCreateEvent() {
        return this.$gate.can('create', 'events')
      },
      beginTime() {
        return moment().format('YYYY-MM-DD');
      },
      endTime() {
        return moment().add(1, 'M').format('YYYY-MM-DD');
      }
    },
    apollo: {
      events: {
        query: GET_EVENTS,
        variables() {
          return {
            ...defaultPaginationConfig,
            category: [this.selectedCategory.key],
            latitude: this.latitude,
            longitude: this.longitude,
            radius: this.radius,
            begin_time: this.beginTime,
            end_time: this.endTime
          }
        },
      }
    },
    created() {
      // this.setUserPosition();
      this.initialRadius(this.radiusKey);
    },
    mounted() {
      this.$on('close', () => {
        this.$ionic.modalController.dismiss()
      })
      this.$on('selected-event-type', (key) => {

        if (key === 'TRAINING') {
          this.createEvent();
        }

        if (key === 'COMPETITION') {
          this.createCompetition();
        }

      })
    },
    methods: {
      createEvent() {
        this.$router.push({name: this.$routeNames.CREATE_EVENT})
      },

      createCompetition() {
        this.$router.push({name: this.$routeNames.CREATE_COMPETITION})
      },

      async doRefresh(event) {
        this.resetInfiniteScroll();
        await this.$apollo.queries.events.refetch()
        event.target.complete();
      },
      openCreateEventModal() {
        return this.$ionic.modalController
          .create({
            component: SelectEventTypeModal,
            componentProps: {
              parent: this,
            },
          })
          .then(m => m.present())
      },
      selectCategory(event) {
        this.resetInfiniteScroll();

        this.selectedCategory = this.categories[event.detail.value];
      },
    }
  }
</script>
