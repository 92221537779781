<template>
  <base-modal :title="$t('selecteventtype')" @close="close">

    <ion-content>
      <ion-list>
        <ion-item button detail="false" @click="selectEventType('TRAINING')">
          <div class="ion-padding-vertical">
            <ion-label class="title">{{ $t('event') }}</ion-label>
            <ion-text class="subtitle text-wrap">
              {{ $t('createeventdescription') }}
            </ion-text>
          </div>

          <ion-icon slot="end" name="arrow-forward" />
        </ion-item>

        <ion-item v-if="canCreateCompetitions" button detail="false"  @click="selectEventType('COMPETITION')" >
          <div class="ion-padding-vertical">
            <ion-label class="title">{{ $t('competition') }}</ion-label>
            <ion-text class="subtitle">
              {{ $t('createcompetitiondescription') }}
            </ion-text>
          </div>
          <ion-icon slot="end" name="arrow-forward" />
        </ion-item>
      </ion-list>
    </ion-content>
  </base-modal>
</template>

<style scoped>
  .title {
    font-size: 1.2rem
  }
  .subtitle {
    color: #AEAEAE
  }
</style>
<script>
  import BaseModal from './BaseModal.vue';
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { FEATURE_FLAG_MAPPING } from "@/configs";

  export default {
    components: {
      BaseModal,
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
      canCreateCompetitions() {
        if (!this.user) {
          return false;
        }

        // Check if the user can create a competition for any of his/her facilities
        return this.user.facilities.some( (facility) => {
          return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.CAN_CREATE_COMPETITIONS, this.user.id, {
            facility_id: facility.id
          })
        } );
      }
    },
    methods: {
      close() {
        this.$ionic.modalController.dismiss();
      },
      selectEventType(key) {
        this.$parent.$emit('selected-event-type', key)
        this.close();
      }
    }
  }
</script>
